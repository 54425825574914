.card {
  background-color: #F1F1F1;
  color: #727272;
  border-radius: 3px;
  padding: 1rem;
}

.project-title {
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 1.75rem;
  margin-top: 0.5rem;
}

.project-image {
  max-width: 470px;
  border-radius: 3px;
}

.tech,
.underline {
  font-weight: 600;
  border-bottom: 2px solid #ACCBC5;
}

.project-buttons {
  display: flex;
  justify-content: space-evenly;
}

.card-button {
  font-family: 'Montserrat', sans-serif;
  background-color: #727272;
  border: none;
  color: #F1F1F1;
  font-weight: 600;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  text-transform: uppercase;
}

.card-button:hover {
  cursor: pointer;
  color: #ACCBC5;
}

@media screen and (max-width: 1050px) {
  .project-title {
    font-size: 1.75rem;
  }

  .project-image {
    max-width: 765px;
  }

  .tech,
  .project-description,
  .stack {
    font-size: 1.25rem;
  }

  .card-button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .project-image {
    max-width: 570px;
  }
}

@media screen and (max-width: 550px) {
  .project-title {
    font-size: 1.25rem;
  }

  .project-image {
    max-width: 290px;
  }

  .tech,
  .project-description,
  .stack,
  .card-button {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 350px) {
  .project-image {
    max-width: 225px;
  }

  .card-button {
    font-size: 0.5rem;
  }
}
