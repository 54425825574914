.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.contact-title {
  font-family: 'Playfair Display', serif;
  color: #727272;
  font-size: 4rem;
  user-select: none;
  margin-bottom: 0.5rem;
}

.contact-text {
  color: #727272;
  font-weight: 600;
  user-select: none;
}

.linkedin,
.email {
  text-decoration: none;
  color: #727272;
  border-bottom: 1.5px solid #F1F1F1;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 0.75rem;
}

.icon {
  height: 50px;
  padding: 1rem;
}

.icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-text {
    font-size: 0.5rem;
    text-align: center;
    margin: 0.5rem 2.5rem;
  }

  .linkedin,
  .email {
    border-bottom: .05px solid #F1F1F1;
  }

  .icon {
    height: 20px;
    padding: 0.5rem;
  }

  .icon-container {
    margin-top: 0.25rem;
  }
}
