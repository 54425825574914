.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.welcome {
  display: flex;
  padding-right: 4rem;
}

.arc {
  height: 125px;
  z-index: 2;
  align-self: flex-end;
  margin-bottom: 2rem;
  position: relative;
  left: 65px;
}

.home-title {
  font-family: 'Playfair Display', serif;
  color: #727272;
  font-size: 6rem;
  margin-bottom: 0.5rem;
  user-select: none;
  z-index: 1;
}

.first-name {
  color: #F1F1F1;
  text-decoration: underline #727272;
}

.intro {
  font-weight: 600;
  color: #727272;
  user-select: none;
  text-align: center;
}

.work-button {
  font-family: 'Montserrat', sans-serif;
  background-color: #727272;
  border: none;
  padding: 0.75rem 2rem;
  color: #F1F1F1;
  font-weight: 600;
  margin-top: 1rem;
  border-radius: 3px;
}

.work-button:hover {
  cursor: pointer;
  color: #ACCBC5;
}

@media screen and (max-width: 800px) {
  .home-title {
    font-size: 4.5rem;
  }

  .arc {
    height: 100px;
    left: 50px;
    margin-bottom: 1.5rem;
  }

  .welcome {
    padding-right: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .home-title {
    font-size: 2.25rem;
  }

  .arc {
    height: 50px;
    left: 25px;
    margin-bottom: 1rem;
  }

  .welcome {
    padding-right: 1.5rem;
  }

  .intro {
    font-size: 0.5rem;
  }

  .work-button {
    padding: 0.25rem 1rem;
    font-size: 0.5rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 350px) {
  .home-title {
    font-size: 1.5rem;
  }

  .arc {
    height: 35px;
    left: 18px;
    margin-bottom: 0.75rem;
  }

  .welcome {
    padding-right: 1.5rem;
  }

  .intro {
    font-size: 0.4rem;
  }

  .work-button {
    padding: 0.2rem 0.75rem;
    font-size: 0.4rem;
    margin-top: 0.5rem;
  }
}
